.footer-section {
  /* border-radius: 50px 50px 0px 0px; */
  background: var(--dark-grey, #272727);
  display: flex;
  width: 100%;
  padding: 40px 8% 20px 8%;
  flex-direction: column;
  align-items: center;
  gap: 45px;
}
.footer-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  gap: 30px;

}
.footer-top-left {
  display: flex;
  align-items: flex-start;
  gap: 30px;
}
.footer-top-left-link a {
  color: var(--white, #f9f9f9);
  font-family: var(--inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.footer-top-right {
  display: inline-flex;
  align-items: flex-start;
  gap: 25px;
}
.footer-top-right-link i {
  color: var(--white);
  font-size: 22px;
}
.footer-bottom {
  border-top: 1.204px solid var(--white, #f9f9f9);
  display: flex;
  padding: 20px 0px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.footer-bottom-left {
  color: var(--white, #f9f9f9);
  font-family: var(--inter);
  font-size: 14px;
  font-weight: 300;
}
.footer-bottom-right {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}
.footer-bottom-right-link {
  color: var(--white, #f9f9f9);
  text-align: right;
  font-family: var(--inter);
  font-size: 14px;
  font-weight: 300;
}

/*==================== RESPONSIVE ====================*/
@media only screen and (max-width: 768px) {
    .footer-top {
        flex-direction: column;
        gap: 30px;
      }
      .footer-section {
        padding: 40px 5% 20px 5%;
        gap: 25px;
        /* border-radius: 25px 25px 0 0; */
      }
}
@media only screen and (max-width: 568px) {
    .footer-top-left {
        flex-direction: column;
        gap: 30px;
        align-items: center;
      
      }
      .footer-bottom {
        flex-direction: column;
        gap: 25px;
      }
}

@media only screen and (min-width: 769px) and (max-width: 1150px) {
    .footer-section {
        padding: 40px 5% 20px 5%;
        gap: 35px;
      }
}
