.hero-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
  height: 85vh;
  position: relative;
}

.hero-label {
  border-radius: 50px;
  border: 1px solid var(--dark-grey, #272727);
  display: inline-flex;
  padding: 8px 25px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #000;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
}

.hero-title {
  color: var(--dark-grey, #272727);
  font-family: Montserrat;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}

.hero-title-green {
  color: var(--green, #57e0b2);
  font-family: Montserrat;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.hero-img {
  max-height: 55vh;
  position: absolute;
  bottom: 0;
  width: auto;
}

.hero-btn-box {
  display: inline-flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 25px;
  border-radius: 50px;
  background: var(--white, #f9f9f9);
  position: absolute;
  bottom: 7%;
}

.hero-cta-black {
  color: var(--dark-grey, #272727);
  font-family: var(--montserrat);
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 50px;
  border: 2px solid var(--dark-grey, #272727);
  display: flex;
  width: 280px;
  padding: 18px 25px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.hero-cta-green {
  color: var(--white, #f9f9f9);
  font-family: var(--montserrat);
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  width: 280px;
  padding: 18px 25px;
  justify-content: center;
  align-items: center;
  gap: 15px;
  border-radius: 50px;
  background: var(--green, #57e0b2);
}

.hero-cta-green i {
  padding-top: 3px;
}

/*==================== RESPONSIVE ====================*/
@media only screen and (max-width: 768px) {

  .hero-title,
  .hero-title-green {
    font-size: 35px;
  }

  .hero-section {
    padding-top: 40px;
    max-height: none;
    height: 100%;
  }

  .hero-img {
    position: relative;
  }

  .hero-label {
    font-size: 16px;
  }

  .hero-btn-box {
    gap: 10px;
    scale: 0.9;
    bottom: 10%;
  }

  .hero-cta-black {
    font-size: 20px;
    padding: 10px 25px;
    gap: 10px;
    width: 165px;
  }

  .hero-cta-green {
    font-size: 20px;
    padding: 10px 25px;
    gap: 5px;
    width: 165px;
  }

  .hero-cta-green i {
    padding-top: 3px;
    font-size: 20px;
  }

}

@media only screen and (max-width: 768px) {}

@media only screen and (min-width: 769px) and (max-width: 1150px) {

  .hero-title,
  .hero-title-green {
    font-size: 50px;
  }

  .hero-label {
    font-size: 16px;
  }

  .hero-btn-box {
    gap: 15px;
  }

  .hero-cta-black {
    font-size: 25px;
    width: 260px;
    padding: 15px 25px;
    gap: 10px;
  }

  .hero-cta-green {
    font-size: 25px;
    width: 260px;
    padding: 15px 25px;
    gap: 15px;
  }

  .hero-cta-green i {
    padding-top: 3px;
  }
}