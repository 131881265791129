.blogsection-section {
  display: flex;
  width: 100%;
  padding: 80px 8%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
}
.blogsection-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
  width: 100%;
}
.blogsection-top-title {
  color: var(--dark-grey, #272727);
  font-family: Montserrat;
  font-size: 52px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  max-width: 600px;
}
.blogsection-top-title-green {
  color: var(--green, #57e0b2);
  font-family: Montserrat;
  font-size: 52px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.blogsection-cta-green {
  border-radius: 50px;
  background: var(--green, #57e0b2);
  display: flex;
  padding: 15px 30px;
  justify-content: center;
  align-items: center;
  gap: 15px;
  flex-shrink: 0;
  color: var(--white, #f9f9f9);
  font-family: var(--inter);
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.blogsection-cta-green i {
  font-size: 23px;
  padding-top: 2px;
}

.blogsection-bottom {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
}

.blogsection-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
}

.blogsection-box-header {
  position: relative;
}

.blogsection-box-img {
}

.blogsection-box-cta-grey {
  border-radius: 60.983px;
  background: var(--grey, #545454);
  display: flex;
  width: 70px;
  height: 70px;
  padding: 18px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7.623px;
  flex-shrink: 0;
  color: var(--white);
  font-size: 35px;
  position: absolute;
  right: 10px;
  bottom: 0;
}
.blogsection-box-cta-green {
  border-radius: 60.983px;
  background: var(--green, #545454);
  display: flex;
  width: 70px;
  height: 70px;
  padding: 18px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7.623px;
  flex-shrink: 0;
  color: var(--white);
  font-size: 35px;
  position: absolute;
  right: 10px;
  bottom: 0;
}
.blogsection-box-cta-grey i,
.blogsection-box-cta-green i {
  padding-top: 2px;
}

.blogsection-box-infos {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
}

.blogsection-box-label {
  color: var(--dark-grey);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;

  padding: 7.967px 19.917px;
  justify-content: center;
  align-items: center;
  gap: 7.967px;
  border-radius: 39.833px;
  background: #d9d9d9;
}

.blogsection-box-title {
  color: var(--dark-grey, #272727);
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.blogsection-box-infos-box {
  display: flex;
  align-items: center;
  gap: 15px;
}

.blogsection-box-infos-container {
  display: flex;
  align-items: flex-start;
  gap: 5px;
}
.blogsection-box-infos-container i {
  color: var(--green);
  font-size: 18px;
}
.blogsection-box-infos-container-text {
  color: var(--dark-grey, #272727);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}


    /*==================== RESPONSIVE ====================*/
    @media only screen and (max-width: 768px) {
      .blogsection-section {
        padding: 50px 5%;
        gap: 25px;
      }
      .blogsection-top {
        flex-direction: column;
      }
      .blogsection-top-title,
      .blogsection-top-title-green {
        text-align: center;
        font-size: 35px;
      }
      .blogsection-cta-green {
        padding: 12px 25px;
        gap: 10px;
        font-size: 20px;
        font-weight: 500;
    }
    .blogsection-bottom {
      flex-direction: column;
      gap: 25px;
    }
    .blogsection-box-title {
      font-size: 20px;
    }
    .blogsection-box-cta-grey,
    .blogsection-box-cta-green {
      width: 50px;
      height: 50px;
    }
    .blogsection-box-cta-grey i,
    .blogsection-box-cta-green i {
      font-size: 22px;
    }
    .blogsection-box-infos-box {
      flex-direction: column;
      align-items: flex-start;
    }
    
    }
    
    @media only screen and (min-width: 769px) and (max-width: 1150px) {
      .blogsection-top-title,
      .blogsection-top-title-green {
        font-size: 45px;
      }
    }