.testimonials-section {
  display: flex;
  width: 100vw;
  padding: 50px 5%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 80px;
  overflow: hidden;
  /* border-radius: 50px; */
  background: var(--dark-grey, #272727);
}

.testimonials-top {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  max-width: 860px;
}

.testimonials-title {
  color: var(--white, #f9f9f9);
  text-align: center;
  font-family: Montserrat;
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.testimonials-title-green {
  color: var(--green, #57e0b2);
  font-family: Montserrat;
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.testimonials-description {
  color: var(--white);
  text-align: center;
  font-family: var(--inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.testimonials-bottom {
  position: relative;
  width: 100%;
}

.testimonials-slider {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
}

.testimonials-slider.animating {
  transition: transform 0.5s ease-in-out;
}

.testimonial-card {
  background: #3b3b3b;
  border-radius: 15px;
  padding: 20px;
  margin: 0 10px;
  min-width: 50vw;
  opacity: 0.5;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  position: relative;
  text-align: left;
  box-sizing: border-box;
  display: flex;
  height: 240px;
  gap: 15px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.testimonial-card.active {
  transform: translateY(-20px) scale(1.05);
  opacity: 1;
  z-index: 2;
}

.testimonial-card.next,
.testimonial-card.prev {
  transform: scale(0.9);
  opacity: 0.5;
}
/* .testimonial-top-box {
  display: flex;
  flex-direction: column;
  gap: 15px;
} */
.testimonial-stars {
  font-size: 24px;
  color: var(--green);
}

.testimonial-comment {
  color: #FFF;
  font-family: var(--inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.testimonial-profile {
  display: flex;
  align-items: center;
  gap: 10px;
}

.testimonial-photo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.testimonial-info {
  text-align: left;
}

.testimonial-name {
  color: #fff;
  font-family: var(--inter);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.testimonial-position {
  color: var(--white);
  font-family: var(--inter);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.testimonials-navigation {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.testimonial-prev,
.testimonial-next {
  border: none;
  color: white;
  font-size: 24px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.testimonial-prev {
  background: var(--grey, #545454);
}

.testimonial-next {
  background: var(--green, #57e0b2);
}

/*==================== RESPONSIVE ====================*/
@media only screen and (max-width: 768px) {
  .testimonial-card {
    min-width: 80%;
    height: 300px;
  }

  .testimonials-section {
    padding: 70px 5%;
    border-radius: 25px;

  }

  .testimonials-description {
    font-size: 15px;
  }

  .testimonials-title,
  .testimonials-title-green {
    font-size: 35px;
  }
}

@media (max-width: 480px) {
  .testimonial-card {
    min-width: 90%;
  }

  .testimonial-comment {
    font-weight: 300;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1150px) {

  .testimonials-title,
  .testimonials-title-green {
    font-size: 45px;
  }
}