.aboutsection-section {
    position: relative;
    margin: 80px 8%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end; 
    min-height: 720px; 
    gap: 50px;
  }
  
  .aboutsection-top {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 50px;
    z-index: 10;
    position: relative;
    padding-left: 50px;
  }
  
  .aboutsection-top-title {
    color: var(--white, #f9f9f9);
    font-family: Montserrat;
    font-size: 52px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    max-width: 954px;
    margin-top: -50px;
  }
  
  .aboutsection-top-title-green {
    color: var(--green, #57e0b2);
    font-family: Montserrat;
    font-size: 52px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .aboutsection-top-box {
    display: flex;
    align-items: center;
    gap: 25px;
    max-width: 50%;
  }
  
  .aboutsection-hr {
    border: 2px solid var(--green);
    height: 70px;
    width: 2px;
  }
  
  .aboutsection-top-text {
    color: var(--white);
    font-family: var(--inter);
    font-size: 18px;
    font-weight: 300;
  }
  
  .aboutsection-top-cta {
    display: flex;
    padding: 15px 30px;
    justify-content: center;
    align-items: center;
    gap: 15px;
    flex-shrink: 0;
    border-radius: 50px;
    background: var(--green, #57e0b2);
    color: var(--white, #f9f9f9);
    font-family: var(--inter);
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .aboutsection-top-img {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  
  .aboutsection-bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 35px 150px;
    padding-left: 50px;
    z-index: 10;
    width: 100%;
    box-sizing: border-box;
    gap: 35px;
  }
  
  .aboutsection-bottom-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  
  .aboutsection-bottom-box-title {
    color: var(--white, #f9f9f9);
    text-align: center;
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .aboutsection-bottom-box-text {
    color: var(--white, #f9f9f9);
    text-align: center;
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  .aboutsection-bottom-hr {
    border: 2px solid var(--green);
    height: 100px;
    width: 2px;
  }
  .aboutsection-img-large 
  {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  

      /*==================== RESPONSIVE ====================*/
      @media only screen and (max-width: 768px) {
        .aboutsection-section {
          position: relative;
          margin: 0;
          padding: 50px 5%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end; 
          min-height: 0; 
          gap: 25px;
          background-color: var(--dark-grey);
          border-radius: 25px;
        }
        .aboutsection-img-large  {
          display: none;
        }
        .aboutsection-top-box {
          min-width: 100%;
          flex-direction: column;
          align-items: center;
          gap: 15px;
        }
        .aboutsection-top,
        .aboutsection-bottom {
          padding: 0;
        }
        .aboutsection-top-title {
          margin: 0;
        }
        .aboutsection-top-title,
        .aboutsection-top-title-green {
          font-size: 35px;
          text-align: center;
        }
        .aboutsection-top-cta {
          padding: 12px 25px;
          gap: 10px;
          font-size: 20px;
          font-weight: 500;
      }
      .aboutsection-bottom-box-title {
        font-size: 25px;
      }
      .aboutsection-bottom-box-text {
        font-size: 18px;
      }
      .aboutsection-bottom {
        justify-content: center;
      }
      .aboutsection-hr {
        width: 70px;
        height: 2px;
      }
      .aboutsection-top-text {
        text-align: center;
      }
      .aboutsection-top {
        gap: 15px;
        align-items: center;
      }
      }
      
      @media only screen and (min-width: 769px) and (max-width: 1150px) {
        .aboutsection-top-title,
        .aboutsection-top-title-green {
          font-size: 45px;
        }
      }