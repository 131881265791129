.newsletter-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  padding: 60px 8%;
  gap: 25px;
}

.newsletter-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 19.565px;
  max-width: 55%;
}

.newsletter-title {
  color: var(--dark-grey, #272727);
  font-family: Montserrat;
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}

.newsletter-title-green {
  color: var(--green, #57e0b2);
  font-family: Montserrat;
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}

.newsletter-text {
  color: var(--dark-grey, #272727);
  font-family: var(--inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.newsletter-right-box {
  border-radius: 59.61px;
  border: 2px solid var(--green, #57e0b2);
  display: flex;
  flex-direction: row;
  width: 400px;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

.newsletter-input {
  border: none;
  background: transparent;
  padding: 10px 15px;
  width: 100%;
  color: var(--dark-grey);
  font-family: var(--inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.newsletter-input::placeholder {
  color: var(--grey, #545454);
  font-family: var(--inter);
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.newsletter-btn {
  border-radius: 28px;
  background: var(--green, #57e0b2);
  color: var(--white, #f9f9f9);
  font-family: var(--inter);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  padding: 10px 25px;
  justify-content: center;
  align-items: center;
  gap: 10.382px;
  border: none;
  margin-right: -1px;
}
.newsletter-btn:hover {
  cursor: pointer;
}

/*==================== RESPONSIVE ====================*/
@media only screen and (max-width: 768px) {
  .newsletter-section {
    flex-direction: column;
  }
  .newsletter-right-box {
    width: 100%;
  }
  .newsletter-right {
    min-width: 90%;
  }
  .newsletter-left {
    align-items: center;
  }
}
