body.contact-page {
  background-color: var(--dark-grey);
}

.contact-section {
  display: flex;
  align-items: center;
  gap: 50px;
  justify-content: space-between;
  padding: 140px 10%;

}

.contact-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 35px;
  width: 80%;
}

.contact-title {
  color: var(--white, #f9f9f9);
  font-family: Montserrat;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}

.contact-title-green {
  color: var(--green, #57e0b2);
  font-family: Montserrat;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}

.contact-left-text {
  color: var(--white, #f9f9f9);
  font-family: var(--inter);
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 130%;
}

.contact-left-mail {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}

.contact-left-mail i {

  color: var(--white);
  font-size: 16px;
  display: flex;
  align-items: center;
  height: 100%;
}

.contact-left-mail-text {
  color: var(--white, #f9f9f9);
  font-family: var(--inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.contact-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 37px;
  width: 100%;
}

.contact-right-line {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  align-self: stretch;
  width: 100%;
}

.contact-right-line-box {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.contact-right-line-box-title {
  color: var(--white, #f9f9f9);
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  /* 38.4px */
  letter-spacing: 0.48px;
}

.contact-right-line-box-title-green {
  color: var(--green, #57e0b2);
  font-family: Montserrat;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  letter-spacing: 0.48px;
}

.contact-right-line-box-input {
  background: transparent;
  border: none;
  border-bottom: 1px solid var(--white);
  width: 100%;
  padding: 5px;
  color: var(--white, #F9F9F9);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  /* 28.8px */
  letter-spacing: 0.36px;

}

.contact-right-line-big-box {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.contact-right-line-box-textarea {
  background: transparent;
  border: none;
  border-bottom: 1px solid var(--white);
  min-width: 100%;
  padding: 5px;
  resize: none;
  color: var(--white, #F9F9F9);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  /* 28.8px */
  letter-spacing: 0.36px;
}

.contact-right-line-box-textarea::placeholder {
  background: transparent;
  color: var(--white, #f9f9f9);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 160%;
  /* 28.8px */
  letter-spacing: 0.36px;
}

.contact-right-line-nl {
  display: flex;
  align-items: center;
  gap: 15px;
  align-self: stretch;
}

.contact-right-line-nl-text {
  color: var(--white, #f9f9f9);
  font-family: var(--inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.checkbox {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.checkbox:checked {
  background-color: var(--green);
  border: none;
  background-clip: content-box;
}

.contact-right-submit-btn {
  display: flex;
  padding: 8.372px 20.93px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 41.86px;
  background: var(--green, #57e0b2);
  color: var(--white, #f9f9f9);
  font-family: var(--inter);
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  cursor: pointer;
}
.contact-message-send {
  background: transparent;
  color: var(--white, #f9f9f9);
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 160%;

}
/*==================== RESPONSIVE ====================*/
@media only screen and (max-width: 768px) {
  .contact-section {
    flex-direction: column;
    width: 100%;
    padding: 50px 5%;
  }

  .contact-right-line {
    flex-direction: column;
    min-width: 300px;
  }

  .contact-right,
  .contact-left {
    width: 100%;
  }

  .contact-right-line-box-title {
    font-size: 20px;
  }

  .contact-right-line-box-title-green {
    font-size: 24px;
  }

  .contact-right-submit-btn {
    font-size: 18px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 915px) {
  .contact-section {
    flex-direction: column;
    width: 100%;
    padding: 80px 5%;
  }

  .contact-right-line {
    flex-direction: column;
    min-width: 300px;
  }

  .contact-right,
  .contact-left {
    width: 80%;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1150px) {}