/* SECTION GÉNÉRALE */
.mini-portfolio-section {
    text-align: center;
    padding: 50px 0px 0px 0px;
    background: var(--dark-grey);
    color: white;
    border-radius: 50px 50px 0 0;
    gap: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* TITRE */
.mini-portfolio-title-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    padding: 0 5%;
}

.mini-portfolio-title {
    color: var(--white, #F9F9F9);
    text-align: center;
    font-family: var(--montserrat);
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.mini-portfolio-highlight {
    color: var(--green);
}

.mini-portfolio-subtitle {
    color: var(--white);
    text-align: center;
    font-family: var(--inter);
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

/* === CARROUSEL CLIENTS INFINI === */
.client-carousel-wrapper {
    width: 100%;
    overflow: hidden;
    padding: 35px 0;
    position: relative;
}

.client-carousel {
    display: flex;
    white-space: nowrap;
    gap: 35px;
    animation: scroll 150s linear infinite;
    width: max-content;
}

/* Logos */
.carousel-logo {
    width: auto;
    height: 50px;
    flex-shrink: 0;
}

/* Animation du carrousel */
@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-50%);
    }

    /* Se déplace à gauche pour créer un effet infini */
}

/* GRILLE RESPONSIVE DES PROJETS */
.project-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 40px;
    padding: 0 5%;
}

/* TABLETTE : 2 COLONNES */
@media (max-width: 1024px) {
    .project-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

/* MOBILE : 1 COLONNE */
@media (max-width: 768px) {
    .project-grid {
        grid-template-columns: repeat(1, 1fr);
    }
    .mini-portfolio-section {
        margin-top: -32px;
        z-index: 1;
        position: relative;
    }
    .client-carousel-wrapper {
        padding: 10px 0;
    }
}

/* CARTES DES PROJETS */
.project-card {
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    cursor: pointer;
}

/* IMAGE DES PROJETS */
.project-image {
    width: 100%;
    display: block;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
}

.project-card:hover .project-image {
    transform: scale(1.1);
}

/* OVERLAY LORS DU HOVER */
.project-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(16, 56, 43, 0.9);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    padding: 20px;
    gap: 25px;
}

.project-card:hover .project-overlay {
    opacity: 1;
}

/* LOGO DU CLIENT SUR L'OVERLAY */
.project-logo {
    height: 30px;
    width: auto;
    margin-bottom: 10px;
}

/* TITRE DU PROJET */
.project-title {
    color: var(--white, #F9F9F9);
    text-align: center;
    font-family: Montserrat;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

/* DESCRIPTION DU PROJET */
.project-description {
    color: var(--white, #F9F9F9);
    text-align: center;
    font-family: var(--inter);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

/* BOUTON VISIT WEBSITE */
.project-button {
    background: #57e0b2;
    color: var(--white, #F9F9F9);
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 8px 15px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 14px;
    transition: background 0.3s;
}

.project-button:hover {
    background: #45c99d;
}


.mini-portfolio-cta {
    color: var(--white, #f9f9f9);
    font-family: var(--montserrat);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    width: 280px;
    padding: 15px 25px;
    justify-content: center;
    align-items: center;
    gap: 15px;
    border-radius: 50px;
    background: var(--green, #57e0b2);
}